body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: #f0f0f0;
    background-color: #1a1a2e;
    margin: 0;
    padding: 0;
}

.footer {
    text-align: center;
    padding: 1rem 0;
    background-color: #0f3460;
    margin-top: 2rem;
}

.notification-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.notification-controls button {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.2s;
}

.notification-controls button:hover {
  background-color: #f0f0f0;
}

.game-times {
    font-size: 1.2rem;
    color: #ffe261;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
} 