.game-container {
    width: 100%;
    max-width: 90vw;
    height: 900px;
    margin: 0 auto;
    display: block;
}

.game-container-wrapper {
    position: relative;
    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
}

.game-container-wrapper.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: none;
    z-index: 1000;
}

.game-container-wrapper.fullscreen .game-container {
    width: 100vw;
    height: 100vh;
    max-width: none;
}

.waiting-screen {
    background-color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #16db93;
    font-size: 2rem;
    text-align: center;
    padding: 2rem;
}

.overlay {
    position: absolute;
    bottom: 20px;
    right: 20px;
    transform-origin: bottom right;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.fullscreen-button {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.fullscreen-button:hover {
    background: rgba(0, 0, 0, 0.7);
} 