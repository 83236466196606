.timer-container {
    text-align: center;
    padding: 3rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 2rem;
}

/* Mobile layout */
@media (max-width: 768px) {
    .timer-container {
        flex-direction: column-reverse;
        padding: 2rem 1rem;
        gap: 1rem;
    }

    .timer-content {
        width: 100%;
    }

    .timer-image {
        width: 150px;
        height: 150px;
    }
}

.timer-container.compact {
    padding: 0.5rem 1rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
}

.timer-content {
    flex: 1;
}

.timer-image {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.compact-text {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000;
}

.timer-heading {
    font-size: 2.5rem;
    color: #e94560;
    margin-bottom: 0.5rem;
}

.countdown-display {
    font-size: 4rem;
    font-weight: bold;
    color: #16db93;
    margin: 1rem 0;
    text-shadow: 0 0 10px rgba(22, 219, 147, 0.5);
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
} 